import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { DebounceInput } from 'react-debounce-input';

const Input = styled(DebounceInput)`
  border: 1px solid black;
  padding-left: 10px;
  height: 25px;
  width: 100%;
  background: white;
  color: black;
  box-sizing: border-box;
  font-size: 15px;
  font-family: ${(props) => props.theme.fonts.default};

  &::placeholder {
    color: rgba(0,0,0,0.5);
  }

  ${up('tablet')} {
    width: 250px;
  }
}
`;

// eslint-disable-next-line react/display-name
const TextInput = forwardRef((props, ref) => {
  return (
    <Input
      type="text"
      ref={ref}
      debounceTimeout={350}
      onChange={props.onChange}
      onKeyUp={props.onKeyUp}
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus={props.autoFocus}
      {...props}
    />
  );
});

export default TextInput;
