require('./src/styles/fonts.css');

function intersectionObserverSupported() {
  if (
    'IntersectionObserver' in window &&
    'IntersectionObserverEntry' in window &&
    'intersectionRatio' in window.IntersectionObserverEntry.prototype
  ) {
    // Minimal polyfill for Edge 15's lack of `isIntersecting`
    // See: https://github.com/w3c/IntersectionObserver/issues/211
    if (!('isIntersecting' in window.IntersectionObserverEntry.prototype)) {
      Object.defineProperty(
        window.IntersectionObserverEntry.prototype,
        'isIntersecting',
        {
          get: function () {
            return this.intersectionRatio > 0;
          },
        }
      );
    }
    return true;
  }

  return false;
}

function smoothScrollSupported() {
  return 'scrollBehavior' in document.documentElement.style;
}

function loadScript(src) {
  var js = document.createElement('script');
  js.src = src;
  document.head.appendChild(js);
}

exports.onClientEntry = () => {
  const polyfills = [];

  if (!intersectionObserverSupported()) {
    polyfills.push('IntersectionObserver');
  }

  if (!smoothScrollSupported()) {
    polyfills.push('smoothscroll');
  }

  if (polyfills.length > 0) {
    loadScript(
      `https://polyfill.io/v3/polyfill.min.js?features=${polyfills.join('%2C')}`
    );
  }
};
