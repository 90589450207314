import React, { memo } from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

import defaultShareImage from '../images/default_share_image.jpg';

const Seo = ({ location, pageContext }) => {
  const {
    site: { buildTime, siteMetadata },
    datoCmsSite: { globalSeo },
  } = useStaticQuery(graphql`
    query SeoQuery {
      site {
        buildTime(formatString: "YYYY-MM-DD")
        siteMetadata {
          author
          backgroundColor
          keywords
          ogLanguage
          pathPrefix
          siteDescription
          siteHeadline
          siteLanguage
          siteLogo
          siteTitle
          siteTitleAlt
          siteTitleShort
          siteUrl
          sitemapPath
          themeColor
        }
      }
      datoCmsSite {
        globalSeo {
          siteName
          fallbackSeo {
            title
            description
            twitterCard
            image {
              url
            }
          }
        }
      }
    }
  `);

  const { post, slug } = pageContext;

  const homeURL = `${siteMetadata.siteUrl}/`;
  const URL = (post && slug && `${siteMetadata.siteUrl}/${slug}`) || homeURL;

  const title =
    (post && post.seo && post.seo.title) ||
    globalSeo.siteName ||
    siteMetadata.siteTitle;
  const description =
    (post && post.seo && post.seo.description) ||
    globalSeo.fallbackSeo.description ||
    siteMetadata.siteDescription;
  const image =
    (post && `${homeURL}og-images/${slug}.jpg`) || `${URL}${defaultShareImage}`;
  const { twitterCard } = (post && post.seo) || globalSeo.fallbackSeo;

  // schema.org in JSONLD format
  // https://developers.google.com/search/docs/guides/intro-structured-data
  // You can fill out the 'author', 'creator' with more data or another type (e.g. 'Organization')

  const schemaOrgWebPage = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: URL,
    headline: description,
    inLanguage: 'en',
    mainEntityOfPage: homeURL,
    description: description,
    name: title,
    author: {
      '@type': 'Person',
      name: siteMetadata.author,
    },
    copyrightHolder: {
      '@type': 'Person',
      name: siteMetadata.author,
    },
    copyrightYear: new Date().getYear(),
    creator: {
      '@type': 'Person',
      name: siteMetadata.author,
    },
    publisher: {
      '@type': 'Person',
      name: siteMetadata.author,
    },
    datePublished: '2019-07-01',
    dateModified: buildTime,
    image: {
      '@type': 'ImageObject',
      url: image,
    },
  };

  // Initial breadcrumb list

  const itemListElement = [
    {
      '@type': 'ListItem',
      item: {
        '@id': URL,
        name: 'Homepage',
      },
      position: 1,
    },
  ];

  const breadcrumb = {
    '@context': 'http://schema.org',
    '@type': 'BreadcrumbList',
    description: 'Breadcrumbs list',
    name: 'Breadcrumbs',
    itemListElement,
  };

  const facebookSeo = [
    { property: 'og:locale', content: siteMetadata.ogLanguage },
    { property: 'og:url', content: URL },
    { property: 'og:site_name', content: title },
    { property: 'og:title', content: title },
    { property: 'og:type', content: 'website' },
    { property: 'og:description', content: description },
    { property: 'og:image', content: image },
    { property: 'og:image:alt', content: description },
    { property: 'og:image:width', content: 1560 },
    { property: 'og:image:height', content: 850 },
    { property: 'og:image:type', content: 'image/jpeg' },
  ];

  const twitterSeo = [
    { name: 'twitter:card', content: twitterCard },
    { name: 'twitter:url', content: URL },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { name: 'twitter:image', content: image },
    { name: 'twitter:image:alt', content: description },
  ];

  return (
    <Helmet
      title={title}
      htmlAttributes={{ lang: siteMetadata.siteLanguage }}
      meta={[
        { name: title, content: description },
        { name: 'description', content: description },
        { name: 'keywords', content: siteMetadata.keywords },
        { name: 'image', content: image },
        {
          name: 'msapplication-TileColor',
          content: siteMetadata.backgroundColor,
        },
        { name: 'msapplication-config', content: 'browsersiteMetadata.xml' },
        ...facebookSeo,
        ...twitterSeo,
      ]}
    >
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgWebPage)}
      </script>
      <script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
    </Helmet>
  );
};

export default memo(Seo);
