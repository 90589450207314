import React, { useContext, useState, useMemo } from 'react';

const QueryContext = React.createContext();

function QueryProvider(props) {
  const [query, setQuery] = useState('');

  const value = useMemo(() => {
    return {
      query,
      setQuery,
    };
  }, [query, setQuery]);

  return <QueryContext.Provider value={value} {...props} />;
}

function useQuery() {
  const context = useContext(QueryContext);

  if (!context) {
    throw new Error('useQuery must be used within a QueryProvider');
  }
  return context;
}

export { QueryProvider, useQuery };
