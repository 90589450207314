/* eslint-disable no-undef */
import { useMemo } from 'react';

import { useAllPosts } from './useAllPosts';

const useAllAuthors = () => {
  const allPosts = useAllPosts();

  return useMemo(() => {
    return [...new Set(allPosts.map((post) => post.author))].sort(
      (current, next) => {
        if (current < next) {
          return -1;
        }

        if (current > next) {
          return 1;
        }

        return 0;
      }
    );
  }, [allPosts]);
};

export default useAllAuthors;
