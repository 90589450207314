import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const allCategoriesQuery = graphql`
  {
    allDatoCmsCategoryName(sort: { fields: index }) {
      nodes {
        index
        name
      }
    }
  }
`;

const useAllCategories = () => {
  const {
    allDatoCmsCategoryName: { nodes: allCategories },
  } = useStaticQuery(allCategoriesQuery);

  return useMemo(() => {
    return allCategories;
  }, [allCategories]);
};

export default useAllCategories;
