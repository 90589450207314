import React, { useState, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';

import { useQuery } from '../context/queryContext';
import { useFilter } from '../context/filterContext';
import useAllCategories from '../hooks/useAllCategories';

const Container = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.pageWidth};
  padding-left: ${(props) => props.theme.pagePaddingMobile};
  margin: 0 auto;
  display: none;

  ${up('tablet')} {
    display: block;
    padding-left: ${(props) => props.theme.pagePadding};
  }
`;

const StatusText = styled.div`
  font-size: 1rem;
  line-height: 1.2;
  padding-left: 3.46rem;

  ${up('tablet')} {
    font-size: 1.3333rem;
    line-height: 1.15;
    padding-left: 17rem;
  }
`;

const Header = styled.h1`
  /* padding-left: 1rem; */
  margin-bottom: 0;
`;

const Status = ({ location }) => {
  const { query } = useQuery();
  const { selectedCategory, selectedSort, selectedAuthor } = useFilter();
  const categories = useAllCategories();
  let status = [];

  if (selectedSort) {
    status.push(getSortStatus(selectedSort));
  }

  if (selectedCategory) {
    status.push(
      `${selectedCategory}. ${
        categories.find((category) => category.index === selectedCategory).name
      }`
    );
  }

  if (selectedAuthor) {
    status.push(selectedAuthor);
  }

  if (query) {
    status.push(`Suche: ${query}`);
  }

  if (!query && !selectedAuthor && !selectedCategory && !selectedSort) {
    status.push(getPageName(location));
  }

  if (location !== '/') {
    status = ['An die Nachwelt'];
  }

  return (
    <Container>
      <StatusText>
        <Header>{status.join(' | ')}</Header>
      </StatusText>
    </Container>
  );
};

function getSortStatus(selectedSort) {
  switch (selectedSort) {
    case 'chronological':
      return 'Chronologisch';
    case 'random':
      return 'Random';
    default:
      return '';
  }
}

function getPageName(location) {
  switch (location) {
    case '/kontakt':
      return 'Kontakt';
    case '/impressum':
      return 'Impressum';
    default:
      return 'An die Nachwelt';
  }
}

export default Status;
