import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

function getColors({
  dark,
  light,
  white,
  theme: {
    colors: { yellow, lightYellow },
  },
}) {
  if (dark) {
    return css`
      background-color: black;
      color: ${lightYellow};
      border: 1px solid transparent;
      border-color: ${lightYellow};
    `;
  } else if (light) {
    return css`
      background-color: ${yellow};
      color: black;
      border: 1px solid transparent;
      border-color: black;
    `;
  } else if (white) {
    return css`
      background-color: white;
      color: black;
      border: 1px solid transparent;
      border-color: black;
    `;
  }
}

function getSize({ small, medium, large }) {
  if (large) {
    return css`
      height: 1.6666rem;
      width: 7.6666rem;
      font-size: 1rem;
    `;
  } else if (medium) {
    return css`
      height: 1.6666rem;
      width: 5.6666rem;
      font-size: 1rem;
      color: white;
    `;
  } else if (small) {
    return css`
      height: 1.2rem;
      width: 2.8rem;
      font-size: 0.6666rem;
      letter-spacing: 0.08em;

      ${up('tablet')} {
        width: 3.2rem;
      }
    `;
  }
}

function getHover({
  nohover,
  theme: {
    hover,
    colors: { accent },
  },
}) {
  return (
    !nohover &&
    hover(`
    color: ${accent};
    border: 1px solid transparent;
    border-color: ${accent};
  `)
  );
}

function getActive({ active }) {
  if (active) {
    return css`
      background-color: white;
      color: black;
    `;
  }
}

const Button = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  color: white;
  cursor: pointer;
  transition: border-color 0.3s, color 0.3s;

  a {
    color: inherit;
  }

  ${getSize}
  ${getColors}
  ${getHover}
  ${getActive}
`;

export default Button;
