const slugify = require('slugify');

function generateSlug({
  author,
  dateText,
  categoryIndex,
  category: { name: categoryName },
}) {
  return slugify(`${author}-${dateText}-${categoryName}-${categoryIndex}`, {
    strict: true,
    lower: true,
    locale: 'de',
  });
}

module.exports = generateSlug;
