import React, { useContext, useState, useMemo } from 'react';

const FilterContext = React.createContext();

function FilterProvider(props) {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedAuthor, setSelectedAuthor] = useState(null);
  const [selectedSort, setSelectedSort] = useState(null);

  const value = useMemo(() => {
    return {
      selectedCategory,
      setSelectedCategory,
      selectedSort,
      setSelectedSort,
      selectedAuthor,
      setSelectedAuthor,
    };
  }, [selectedCategory, selectedSort, selectedAuthor]);

  return <FilterContext.Provider value={value} {...props} />;
}

function useFilter() {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('useCount must be used within a CountProvider');
  }
  return context;
}

export { FilterProvider, useFilter };
