import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import { up } from 'styled-breakpoints';

import Seo from '../components/Seo';
import Navigation from '../components/Navigation';
import GlobalStyles from '../styles/GlobalStyles';
import theme from '../styles/theme';

import { FilterProvider } from '../context/filterContext';
import { QueryProvider } from '../context/queryContext';
import { PostsProvider } from '../context/postsContext';
import { CurrentYearProvider } from '../context/currentYearContext';

const Container = styled.div`
  width: 100%;
  max-width: ${(props) => props.theme.pageWidth};
  padding: ${(props) => props.theme.pagePaddingMobile};
  margin: 0 auto;

  ${up('tablet')} {
    padding: ${(props) => props.theme.pagePadding};
  }
`;

const Layout = ({ children, location, pageContext }) => {
  return (
    <ThemeProvider theme={theme}>
      <Seo location={location.pathname} pageContext={pageContext} />
      <GlobalStyles />
      <FilterProvider>
        <QueryProvider>
          <CurrentYearProvider>
            <PostsProvider>
              <Navigation location={location.pathname} />
              <Container>{children}</Container>
            </PostsProvider>
          </CurrentYearProvider>
        </QueryProvider>
      </FilterProvider>
    </ThemeProvider>
  );
};

export default Layout;
