/* eslint-disable max-len */
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { up } from 'styled-breakpoints';

import Reset from './Reset';
import FormReset from './FormReset';
import Transitions from './Transitions';
import background from '../images/background.jpg';

const Base = createGlobalStyle`
  html, body {
    font-size: 15px;
    font-family: ${(props) => props.theme.fonts.default}, Helvetica, sans-serif;
    height: 100%;
    width: 100%;
    line-height: 1.33;
    -webkit-text-size-adjust: 100%;
    background-color: black;
    color: white;
    background-image: url(${background});
    background-size: cover;
  }

  * { 
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  a {
    text-decoration: none;
    color: black;
  }

  h1 {
    font-size: 1rem;
    font-family: ${(props) => props.theme.fonts.bold};
    margin-bottom: 1.6666rem;

    ${up('tablet')} {
      font-size: 1.33rem;
    }
  }

  mark {
    background-color: initial;
    color: ${(props) => props.theme.colors.accent}
  }

  input, select {
    font-size: 16px !important;
    border-radius: 0 !important;
    box-shadow: none !important;
  }
`;

const GlobalStyles = () => (
  <>
    <Reset />
    <FormReset />
    {/* <Fonts /> */}
    <Base />
    <Transitions />
  </>
);

export default GlobalStyles;
