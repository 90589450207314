import { useCallback } from 'react';
import { useQuery } from '../context/queryContext';
import { useFilter } from '../context/filterContext';
import { navigate } from 'gatsby';

function useReset() {
  const { setQuery } = useQuery();
  const {
    setSelectedCategory,
    setSelectedSort,
    setSelectedAuthor,
  } = useFilter();

  return useCallback(() => {
    scrollToTop();
    setSelectedCategory(null);
    setSelectedSort(null);
    setSelectedAuthor(null);
    setQuery(null);
  }, [setSelectedCategory, setSelectedSort, setSelectedAuthor, setQuery]);
}

function scrollToTop() {
  typeof window !== 'undefined' &&
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
}

export { useReset };
