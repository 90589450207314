import { useState, useCallback, useMemo } from 'react';

const useExpanded = () => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = useCallback(() => {
    setExpanded((prev) => !prev);
  }, []);

  return [expanded, toggleExpanded];
};

export { useExpanded };
