module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/opt/build/repo/src/templates/Layout.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-datocms-starter","short_name":"starter","start_url":"/","background_color":"#222","theme_color":"#000","display":"minimal-ui","icon":"src/images/favicon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"f26ea8a586818f5f6df004cfc9782722"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
