import React, { useContext, useMemo, useRef } from 'react';

import { useFilter } from './filterContext';
import { useQuery } from './queryContext';
import { useSearch } from '../hooks/useSearch';

const PostsContext = React.createContext();

function PostsProvider(props) {
  const { selectedCategory, selectedSort, selectedAuthor } = useFilter();
  const { query } = useQuery();
  const randomPostsRef = useRef(null);
  let dateLabels = [];

  let searchResults = useSearch();

  let filteredByAuthor = searchResults.filter(({ author }) =>
    selectedAuthor ? author === selectedAuthor : true
  );

  let posts = sortPosts(filteredByAuthor, selectedSort, randomPostsRef)
    .filter(({ category: { index } }) =>
      selectedCategory ? index === selectedCategory : true
    )
    .map((post) => {
      if (selectedSort !== 'chronological') {
        return post;
      }
      let labels = {};

      const dateLabel = getDateLabel(post.date);

      if (!dateLabels.includes(dateLabel)) {
        dateLabels.push(dateLabel);
        labels.dateLabel = dateLabel;

        return {
          ...post,
          dateLabel,
        };
      }

      return post;
    });

  const value = useMemo(() => {
    typeof window !== 'undefined' && window.scrollTo(0, 0);

    return {
      posts,
    };
  }, [posts]);

  return <PostsContext.Provider value={value} {...props} />;
}

function usePosts() {
  const context = useContext(PostsContext);

  if (!context) {
    throw new Error('usePosts must be used within a post provider');
  }
  return context;
}

function getDateLabel(dateString) {
  if (!dateString) {
    return 'Undatiert';
  }
  const date = new Date(dateString);

  return `${date.toLocaleString('de', {
    month: 'long',
  })} ${date.getFullYear()}`;
}

function sortPosts(posts, sortType, randomPostsRef) {
  const unsortedArray = [...posts];
  if (sortType === 'chronological') {
    return unsortedArray.sort(chronologicalSort).reverse().sort(shiftUndated);
  } else if (sortType === 'book') {
    return unsortedArray;
  } else if (!sortType) {
    // if (!randomPostsRef.current) {
    //   randomPostsRef.current = randomSort(unsortedArray);
    // }
    // return randomPostsRef.current;
    return randomSort(unsortedArray);
  }
}

function chronologicalSort(current, next) {
  if (!current.date) {
    return -1;
  }

  return current.date < next.date ? -1 : current.date > next.date ? 1 : 0;
}

function shiftUndated(current) {
  if (!current.date) {
    return 1;
  } else {
    return -1;
  }
}

function randomSort(array) {
  for (var i = array.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = array[i];
    array[i] = array[j];
    array[j] = temp;
  }

  return array;
}

export { PostsProvider, usePosts };
