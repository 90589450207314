import { useMemo } from 'react';
import { useStaticQuery, graphql } from 'gatsby';

const allPostsQuery = graphql`
  {
    allDatoCmsVorahnungen(sort: { fields: position, order: ASC }) {
      nodes {
        author
        date
        dateText
        index
        categoryIndex
        info
        text
        originalId
        date
        dateText
        postType {
          postType
        }
        category {
          name
          index
        }
        seo {
          title
          description
          twitterCard
        }
      }
    }
    allDatoCmsTod(sort: { fields: position, order: ASC }) {
      nodes {
        author
        date
        dateText
        index
        categoryIndex
        info
        text
        originalId
        date
        dateText
        postType {
          postType
        }
        category {
          name
          index
        }
        seo {
          title
          description
          twitterCard
        }
      }
    }
    allDatoCmsKinder(sort: { fields: position, order: ASC }) {
      nodes {
        author
        date
        dateText
        index
        categoryIndex
        info
        text
        originalId
        date
        dateText
        postType {
          postType
        }
        category {
          name
          index
        }
        seo {
          title
          description
          twitterCard
        }
      }
    }
    allDatoCmsAngehoerige(sort: { fields: position, order: ASC }) {
      nodes {
        author
        date
        dateText
        index
        categoryIndex
        info
        text
        originalId
        date
        dateText
        postType {
          postType
        }
        category {
          name
          index
        }
        seo {
          title
          description
          twitterCard
        }
      }
    }
    allDatoCmsLetzteWuensche(sort: { fields: position, order: ASC }) {
      nodes {
        author
        date
        dateText
        index
        categoryIndex
        info
        text
        originalId
        date
        dateText
        postType {
          postType
        }
        category {
          name
          index
        }
        seo {
          title
          description
          twitterCard
        }
      }
    }
    allDatoCmsWiderstand(sort: { fields: position, order: ASC }) {
      nodes {
        author
        date
        dateText
        index
        categoryIndex
        info
        text
        originalId
        date
        dateText
        postType {
          postType
        }
        category {
          name
          index
        }
        seo {
          title
          description
          twitterCard
        }
      }
    }
    allDatoCmsDokumentation(sort: { fields: position, order: ASC }) {
      nodes {
        author
        date
        dateText
        index
        categoryIndex
        info
        text
        originalId
        date
        dateText
        postType {
          postType
        }
        category {
          name
          index
        }
        seo {
          title
          description
          twitterCard
        }
      }
    }
    allDatoCmsPoesie(sort: { fields: position, order: ASC }) {
      nodes {
        author
        date
        dateText
        index
        categoryIndex
        info
        text
        originalId
        date
        dateText
        postType {
          postType
        }
        category {
          name
          index
        }
        seo {
          title
          description
          twitterCard
        }
      }
    }
    allDatoCmsAbschied(sort: { fields: position, order: ASC }) {
      nodes {
        author
        date
        dateText
        index
        categoryIndex
        info
        text
        originalId
        date
        dateText
        postType {
          postType
        }
        category {
          name
          index
        }
        seo {
          title
          description
          twitterCard
        }
      }
    }
    allDatoCmsAnDieMenschheit(sort: { fields: position, order: ASC }) {
      nodes {
        author
        date
        dateText
        index
        categoryIndex
        info
        text
        originalId
        date
        dateText
        postType {
          postType
        }
        category {
          name
          index
        }
        seo {
          title
          description
          twitterCard
        }
      }
    }
  }
`;

const useAllPosts = () => {
  const data = useStaticQuery(allPostsQuery);

  return useMemo(() => {
    return [
      ...data.allDatoCmsVorahnungen.nodes,
      ...data.allDatoCmsTod.nodes,
      ...data.allDatoCmsKinder.nodes,
      ...data.allDatoCmsAngehoerige.nodes,
      ...data.allDatoCmsLetzteWuensche.nodes,
      ...data.allDatoCmsWiderstand.nodes,
      ...data.allDatoCmsDokumentation.nodes,
      ...data.allDatoCmsPoesie.nodes,
      ...data.allDatoCmsAbschied.nodes,
      ...data.allDatoCmsAnDieMenschheit.nodes,
    ].map((post) => ({
      author: post.author,
      date: post.date,
      dateText: post.dateText,
      index: post.index,
      categoryIndex: post.categoryIndex,
      info: post.info,
      text: post.text,
      originalId: post.originalId,
      postType: post.postType,
      category: post.category,
      seo: post.seo,
    }));
  }, [data]);
};

export { useAllPosts };
