import React, { useCallback } from 'react';
import styled from 'styled-components';
import { useSelect } from 'downshift';

import ArrowSvg from '../images/svg/arrow.svg';
import CloseSvg from '../images/svg/close.svg';

const Container = styled.div`
  width: 100%;
  border: 1px solid black;
  background: white;
  color: black;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  cursor: pointer;
`;

const Option = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 23px;
  cursor: pointer;
  color: ${(props) => props.active && props.theme.colors.accent};
`;

const Options = styled.div`
  position: absolute;
  z-index: 1;
  top: -1px;
  right: -1px;
  left: -1px;
  background: white;
  z-index: 2;
  border: 1px solid black;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};

  &:focus {
    outline: none;
  }
`;

const CloseButton = styled(CloseSvg)`
  margin-left: auto;
  margin-right: 3px;
  z-index: 1;
`;

const Dropdown = ({
  items,
  itemsNameMap,
  placeholder,
  handleSelectedItemChange,
  selectedItem,
  clearable,
  toggleExpanded,
}) => {
  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useSelect({
    items,
    selectedItem,
    onSelectedItemChange: ({ selectedItem }) => {
      handleSelectedItemChange(selectedItem);
      toggleExpanded();
    },
  });

  const clearSelection = useCallback(
    (event) => {
      event.stopPropagation();
      selectItem(null);
    },
    [selectItem]
  );

  const getName = useCallback(
    (item) => {
      const entry = itemsNameMap.find((name) => name.value === item);
      return entry ? entry.name : undefined;
    },
    [itemsNameMap]
  );

  return (
    <Container>
      <Option {...getToggleButtonProps()}>
        {getName(selectedItem) || placeholder}
        {selectedItem && clearable && <CloseButton onClick={clearSelection} />}
        <ArrowSvg />
      </Option>
      <Options {...getMenuProps()} isOpen={isOpen}>
        {isOpen &&
          items.map((item, index) => (
            <Option
              key={index}
              {...getItemProps({ item, index })}
              active={highlightedIndex === index}
            >
              {getName(item)}
            </Option>
          ))}
      </Options>
    </Container>
  );
};

export default Dropdown;
