import React, { useContext, useState, useMemo } from 'react';

const CurrentYearContext = React.createContext();

function CurrentYearProvider(props) {
  const [currentYear, setCurrentYear] = useState(null);

  const value = useMemo(() => {
    return {
      currentYear,
      setCurrentYear,
    };
  }, [currentYear, setCurrentYear]);

  return <CurrentYearContext.Provider value={value} {...props} />;
}

function useCurrentYear() {
  const context = useContext(CurrentYearContext);

  if (!context) {
    throw new Error('useCurrentYear must be used within a CurrentYearProvider');
  }
  return context;
}

export { CurrentYearProvider, useCurrentYear };
