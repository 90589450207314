import React, { useState, useCallback, useRef } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import useOnClickOutside from 'use-onclickoutside';

import Status from './Status';
import SearchMenu from './SearchMenu';
import Button from './common/Button';

import { useExpanded } from '../hooks/useExpanded';
import { useQuery } from '../context/queryContext';
import { useFilter } from '../context/filterContext';
import { useReset } from '../hooks/useReset';
import { useRandomPost } from '../hooks/useRandomPost';

import HamburgerSvg from '../images/svg/hamburger.svg';
import CloseSvg from '../images/svg/close.svg';

const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  padding-top: ${(props) => props.theme.pagePaddingMobile};
  padding-bottom: ${(props) => props.theme.pagePaddingMobile};
  background-color: black;
  padding-left: 4rem;
  height: 45px;

  ${(props) =>
    props.chronological &&
    css`
      &::after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 190px;
        width: 2px;
        height: 100%;
        background-color: white;
      }
    `}

  ${up('tablet')} {
    left: 0;
    padding-top: ${(props) => props.theme.pagePadding};
    padding-bottom: ${(props) => props.theme.pagePadding};
    background-color: initial;
    padding-left: 0;
    z-index: 0;
    height: initial;

    &::after {
      content: none;
      display: none;
    }
  }
`;

const SiteTitle = styled.h1`
  margin-bottom: 0;

  a {
    color: inherit;
  }

  ${up('tablet')} {
    display: none;
  }
`;

const HamburgerButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: ${(props) => props.theme.pagePaddingMobile};

  ${up('tablet')} {
    position: absolute;
    right: ${(props) => props.theme.pagePadding};
    top: ${(props) => props.theme.pagePadding};
    margin-right: 0;
  }
`;

const CloseButton = styled(HamburgerButton)`
  position: absolute;
  right: ${(props) => props.theme.pagePaddingMobile};
  top: ${(props) => props.theme.pagePaddingMobile};

  ${up('tablet')} {
    right: ${(props) => props.theme.pagePadding};
    top: ${(props) => props.theme.pagePadding};
  }

  ${(props) =>
    props.theme.hover(`
    svg {
      .close-stroke {
        stroke: ${props.theme.colors.accent};
      }
    }
  `)}
`;

const Links = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  left: ${(props) => props.theme.pagePaddingMobile};
  padding: 4rem 4rem 6rem 1.5rem;
  background: white;
  border: 2px solid black;
  color: black;

  ${up('tablet')} {
    left: initial;
    width: 15rem;
    height: 16rem;
  }
`;

const Link = styled(GatsbyLink)`
  display: block;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.theme.hover(`
    color: ${props.theme.colors.accent};
  `)}
`;

const ExternalLink = styled.a`
  display: block;
  margin-bottom: 0.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${(props) =>
    props.theme.hover(`
    color: ${props.theme.colors.accent};
  `)}
`;

const FilterButton = styled(Button)`
  width: 3.5rem;
  transition: opacity 0.3s, visibility 0.3s;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};

  ${up('tablet')} {
    display: none;
  }
`;

const RandomButton = styled(FilterButton)`
  background-color: white;
  color: black;
`;

const Navigation = ({ location }) => {
  const ref = useRef(null);
  const [expanded, toggleExpanded] = useExpanded();
  const [searchMenuExpanded, toggleSearchMenuExpanded] = useExpanded();
  const { query } = useQuery();
  const { selectedCategory, selectedSort, selectedAuthor } = useFilter();
  const reset = useReset();
  const goToRandomPost = useRandomPost();

  useOnClickOutside(ref, () => {
    expanded && toggleExpanded();
  });

  return (
    <Container chronological={selectedSort === 'chronological'}>
      <SiteTitle onClick={reset}>
        <GatsbyLink to="/">An die Nachwelt</GatsbyLink>
      </SiteTitle>
      <Status location={location} />
      <FilterButton
        dark
        small
        onClick={toggleSearchMenuExpanded}
        active={selectedCategory || selectedSort || selectedAuthor || query}
        visible={location == '/'}
      >
        Filter
      </FilterButton>
      <RandomButton dark small onClick={goToRandomPost} visible>
        Random
      </RandomButton>
      <HamburgerButton onClick={toggleExpanded}>
        <HamburgerSvg />
      </HamburgerButton>
      {searchMenuExpanded && (
        <SearchMenu toggleExpanded={toggleSearchMenuExpanded} />
      )}
      {expanded && (
        <Links ref={ref} onClick={toggleExpanded}>
          {/* <Link to="/" state={{ vorwortNavigate: true }}>
            Vorwort
          </Link> */}
          <Link to="/" onClick={reset}>
            Start
          </Link>
          <Link to="/kontakt">Kontakt</Link>
          <Link
            as="a"
            href="mailto:sayhello@politicalbeauty.de"
            target="_blank"
            rel="noopener"
          >
            Beitrag hinzufügen
          </Link>
          <ExternalLink
            target="_blank"
            rel="noopener"
            href="https://politicalbeauty.de/impressum.html"
          >
            Impressum
          </ExternalLink>
          <ExternalLink
            target="_blank"
            rel="noopener"
            href="https://politicalbeauty.de/impressum.html#content4-e0"
          >
            Datenschutzerklärung
          </ExternalLink>
          {/* <Link to="/" state={{ nachwortNavigate: true }}>
            Nachwort
          </Link> */}
          <CloseButton>
            <CloseSvg />
          </CloseButton>
        </Links>
      )}
    </Container>
  );
};

function scrollToTop() {
  typeof window !== 'undefined' &&
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
}

export { Container as NavigationContainer };
export default Navigation;
