import { useCallback } from 'react';
import { useAllPosts } from './useAllPosts';
import { navigate } from 'gatsby';

import generateSlug from '../utils/generateSlug';

function useRandomPost() {
  const allPosts = useAllPosts();

  return useCallback(() => {
    const { author, dateText, categoryIndex, category } = allPosts[
      Math.floor(Math.random() * allPosts.length)
    ];

    navigate(
      `/${generateSlug({
        author,
        dateText,
        categoryIndex,
        category,
      })}`
    );
  }, [allPosts]);
}

export { useRandomPost };
