import React, { useRef, useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import useOnClickOutside from 'use-onclickoutside';

import TextInput from './common/TextInput';
import Button from './common/Button';
import Dropdown from './Dropdown';
import Combobox from './Combobox';

import useAllCategories from '../hooks/useAllCategories';
import { useFilter } from '../context/filterContext';
import { useQuery } from '../context/queryContext';
import useAllAuthors from '../hooks/useAllAuthors';

import CloseSvg from '../images/svg/close.svg';

const Container = styled.div`
  background: white;
  border: 1px solid black;
  padding: 38px;
  padding-right: 1.33rem;
  padding-left: 1rem;
  position: fixed;
  top: 0;
  right: 0;
  left: ${(props) => props.theme.pagePaddingMobile};
  z-index: 2;

  ${up('tablet')} {
    position: absolute;
    top: 25px;
    right: 0;
    bottom: 0;
    left: initial;
    width: 425px;
    padding-right: 33px;
    border: 2px solid black;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  flex-direction: column;

  ${Button} {
    margin-top: 0.5rem;
  }

  ${up('tablet')} {
    flex-direction: row;

    ${Button} {
      margin-top: 0;
    }
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 7px;
  right: 13px;
`;

const SearchMenu = ({ toggleExpanded, focus }) => {
  const ref = useRef(null);

  const categories = useAllCategories();
  const allAuthors = useAllAuthors();
  const { query, setQuery } = useQuery();

  const {
    selectedCategory,
    selectedSort,
    setSelectedCategory,
    setSelectedSort,
    selectedAuthor,
    setSelectedAuthor,
  } = useFilter();

  useOnClickOutside(ref, () => {
    toggleExpanded();
  });

  const categoriesNameMap = useMemo(() => {
    return categories.map(({ name, index }) => {
      return {
        name: `${index}. ${name}`,
        value: index,
      };
    });
  }, [categories]);

  const categoriesItems = useMemo(() => {
    return categories.map((category) => category.index);
  }, [categories]);

  const filterItems = useMemo(() => {
    return [null, 'chronological', 'book'];
  }, []);

  const filterNameMap = useMemo(() => {
    return [
      { name: 'Random', value: null },
      { name: 'Chronologisch', value: 'chronological' },
      { name: 'Reihenfolge der Publikation', value: 'book' },
    ];
  }, []);

  const search = useCallback(
    ({ target: { value } }) => {
      if (value.length === 0 || value.length > 2) {
        setQuery(value);
      }
    },
    [setQuery]
  );

  const onKeyUp = useCallback(({ keyCode }) => {
    if (keyCode === 13) {
      toggleExpanded();
    }
  }, []);

  const submitSearch = useCallback(() => {
    setQuery(query);
    toggleExpanded();
  }, [setQuery, query, toggleExpanded]);

  return (
    <Container ref={ref}>
      <Row>
        <TextInput
          value={query}
          placeholder="Ihre Suche"
          onChange={search}
          onKeyUp={onKeyUp}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={focus == 'search'}
        />
        <Button large dark nohover onClick={submitSearch}>
          Suchen
        </Button>
      </Row>
      <Row>
        <Combobox
          placeholder="Autor*in"
          items={allAuthors}
          selectedItem={selectedAuthor}
          handleSelectedItemChange={setSelectedAuthor}
          toggleExpanded={toggleExpanded}
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus={focus == 'author'}
        />
        <Button large dark nohover onClick={toggleExpanded}>
          Suchen
        </Button>
      </Row>
      <Row>
        <Dropdown
          placeholder="Kapitelauswahl"
          clearable
          items={categoriesItems}
          itemsNameMap={categoriesNameMap}
          handleSelectedItemChange={setSelectedCategory}
          selectedItem={selectedCategory}
          toggleExpanded={toggleExpanded}
        />
      </Row>
      <Row>
        <Dropdown
          items={filterItems}
          itemsNameMap={filterNameMap}
          selectedItem={selectedSort}
          handleSelectedItemChange={setSelectedSort}
          toggleExpanded={toggleExpanded}
        />
      </Row>
      <CloseButton onClick={toggleExpanded}>
        <CloseSvg />
      </CloseButton>
    </Container>
  );
};

export default SearchMenu;
