import { css } from 'styled-components';

const theme = {
  fonts: {
    default: 'GillSans',
    bold: 'GillSansBold',
    serif: 'Sabon',
    monospace: 'Courier New',
  },
  transitions: {
    page: 350,
    navigation: 350,
    appear: 650,
  },
  colors: {
    accent: '#D40000',
    yellow: '#FFF7E4',
    lightYellow: '#FFFBF3',
    notebookBlue: '#afcbff',
  },
  pageWidth: '1024px',
  pagePadding: '20px',
  pagePaddingMobile: '10px',
  navigationHeight: '60px',
  hover: (cssrules) => css`
    @media (hover: hover) and (pointer: fine) {
      &:hover {
        ${cssrules}
      }
    }
  `,
  //styled-breakpoints breakpoints
  // breakpoints: {
  //   tablet: '820px',
  //   desktop: '992px',
  //   lgDesktop: '1200px',
  // },
};

export default theme;
