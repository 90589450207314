import React, { useState } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { useCombobox } from 'downshift';

import TextInput from './common/TextInput';

const Container = styled.div`
  position: relative;
`;

const SuggestionList = styled.div`
  position: absolute;
  width: 100%;
  border: 1px solid black;
  top: 24px;
  background: white;
  color: black;
  z-index: 2;
  max-height: 300px;
  overflow-y: scroll;
  border-top: none;
  display: ${(props) => (props.isOpen ? 'block' : 'none')};

  ${up('tablet')} {
    width: 250px;
  }
`;

const Suggestion = styled.div`
  padding-left: 10px;
  padding-right: 10px;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  line-height: 23px;
  cursor: pointer;
  color: ${(props) => props.active && props.theme.colors.accent};
`;

const Separator = styled.div`
  background-color: black;
  height: 1px;
  position: absolute;
  top: 23px;
  left: 10px;
  right: 10px;
`;

const Combobox = ({
  items,
  selectedItem,
  handleSelectedItemChange,
  placeholder,
  autoFocus,
  toggleExpanded,
}) => {
  const [inputItems, setInputItems] = useState(items);

  const {
    isOpen,
    // getToggleButtonProps, //add back if we need button
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox({
    items: inputItems,
    selectedItem,
    onSelectedItemChange: ({ selectedItem }) => {
      handleSelectedItemChange(selectedItem);
      toggleExpanded();
    },
    onInputValueChange: ({ inputValue }) => {
      if (inputValue === '') {
        handleSelectedItemChange(null);
      }
      setInputItems(
        items.filter((item) =>
          item.toLowerCase().includes(inputValue.toLowerCase())
        )
      );
    },
  });
  return (
    <Container {...getComboboxProps()}>
      <TextInput
        {...getInputProps()}
        placeholder={placeholder}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
      />
      {isOpen && inputItems.length > 0 && <Separator />}
      <SuggestionList isOpen={isOpen} {...getMenuProps()}>
        {isOpen &&
          inputItems.map((item, index) => (
            <Suggestion
              active={highlightedIndex === index}
              key={index}
              {...getItemProps({ item, index })}
            >
              {item}
            </Suggestion>
          ))}
      </SuggestionList>
    </Container>
  );
};

export default Combobox;
