import { createGlobalStyle } from 'styled-components';

const Transitions = createGlobalStyle`
  .navigation-transition-enter,
  .navigation-transition-appear,
  .navigation-transition-initial {
    transform: translate3d(0,-100%,0);
  }

  .navigation-transition-enter-done,
  .navigation-transition-appear-done {
    transition: transform ${(props) =>
      props.theme.transitions.navigation}ms ease-in-out;
    transform: none;
  }

  .page-transition-initial {
    opacity: 0;
  }

  .page-transition-enter,
  .page-transition-appear {
    position: absolute;
    opacity: 0;
  }

  .page-transition-enter-done {
    transition: opacity ${(props) =>
      props.theme.transitions.page}ms ease-in-out;
    opacity: 1;
  }

  .page-transition-appear-done {
    transition: opacity ${(props) =>
      props.theme.transitions.appear}ms ease-in-out;
    opacity: 1;
  }

  .page-transition-exit {
    transition: opacity ${(props) =>
      props.theme.transitions.page}ms ease-in-out;
    opacity: 0;
  }
`;

export default Transitions;
