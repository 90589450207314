import { useState, useEffect, useMemo, useRef } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import FlexSearch from 'flexsearch';

import { useQuery } from '../context/queryContext';
import { useAllPosts } from '../hooks/useAllPosts';

const localSearchQuery = graphql`
  {
    localSearchPost {
      publicStoreURL
      publicIndexURL
    }
  }
`;

export const useSearch = () => {
  const {
    localSearchPost: { publicStoreURL, publicIndexURL },
  } = useStaticQuery(localSearchQuery);

  let index = useRef(null);
  let store = useRef(null);
  const { query } = useQuery();
  const allPosts = useAllPosts();

  useEffect(() => {
    const storeRequest = fetch(publicStoreURL).then((response) =>
      response.json()
    );
    const indexRequest = fetch(publicIndexURL).then((response) =>
      response.text()
    );

    Promise.all([storeRequest, indexRequest]).then((values) => {
      const importedTextIndex = FlexSearch.create();
      importedTextIndex.import(values[1]);

      index.current = importedTextIndex;
      store.current = values[0];
    });
  }, [publicStoreURL, publicIndexURL]);

  return useMemo(() => {
    if (!store.current || !index.current) {
      return allPosts;
    }

    const rawResults = query
      ? index.current.search(query)
      : Object.keys(store.current);

    return rawResults.map((id) => store.current[id]);
  }, [query, index, store, allPosts]);
};
